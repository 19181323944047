import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Test = () => {

    const options = {
        chart: {
          type: 'area',
          height: 400,
          toolbar: {
            show: true, // Enables the toolbar with zoom, pan, and other tools
          },
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false, // Removes data labels from the chart
        },
        stroke: {
          curve: 'smooth', // Makes the area curve smooth
          width: 2, // Thickness of the line
        },
        xaxis: {
          type: 'datetime', // Proper datetime x-axis
          categories: ['2024-01-01T00:00:00.000Z','2024-01-02T00:00:00.000Z','2024-01-03T00:00:00.000Z','2024-01-04T00:00:00.000Z','2024-01-05T00:00:00.000Z','2024-01-06T00:00:00.000Z','2024-01-07T00:00:00.000Z'],
          labels: {
            format: 'dd MMM', // Formats x-axis labels
          },
        },
        tooltip: {
          x: {
            format: 'dd MMM', // Tooltip format for dates
          },
        },
        fill: {
          type: 'gradient', // Gradient effect for the fill
          gradient: {
            shade: 'light', // Lighter shade for the gradient
            type: 'vertical', // Vertical gradient
            shadeIntensity: 2,
            gradientToColors: ['#87CEEB'], // Soft blue at the bottom
            inverseColors: true,
            opacityFrom: 2, // Higher opacity at the top
            opacityTo: 2, // Fades at the bottom
            stops: [0, 100], // Start and end points of the gradient
          },
        },
        colors: ['#008FFB'], // Blue color for the line and fill
        grid: {
          show: true, // Displays grid lines
        },
      };
      
      const series = [
        {
          name: 'Series 1',
          data: [31, 40, 28, 51, 42, 109, 100],
        }        
      ];

    return (
        <>
            <div id="chart">
                <ReactApexChart options={options} series={series}  width={'100%'} height={400} />
            </div>
        </>
    )
};

export default Test;