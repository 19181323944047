export const ChartBackground = '#424242'; //'#262d47';

export const Brand = {
    Oncyclo: "Oncyclo",
    Revugam: "Revugam",
    Thymogam: "Thymogam",
    Revugam25: "Revugam-25"
}

export const BrandById = {
    35:'Oncyclo', 
    36:'Revugam', 
    37:'Thymogam', 
    38:'Revugam25'
}

export const Zone = {
    North: "North",
    East: "East",
    South: "South",
    West: "West"
}

export const ZoneById = {
    1000:'West', 
    1001:'East', 
    1002:'North', 
    1003:'South'
};





