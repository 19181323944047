import React from 'react';
import './BrandWisePatientsComp.css';

const BrandWisePatientsItemComp = ({ brandName, patients, onClick, isActive }) => {

    const selectBrandHandler = React.useCallback(() => {
        onClick && onClick();
    }, []);

    return (
        <>
            <div className={`col-4 ${isActive ? '' : 'graysacle'}`} onClick={selectBrandHandler}>
                <div className={`top-performer-wrapper product-performer-zone-wise product-performer-zone-wise-modify ${brandName.toLowerCase()}`}>
                    <b className={`heading ${brandName.toLowerCase()}`}>{brandName.toLowerCase()}</b>
                    <table className={`${brandName.toLowerCase()}`}>
                        <tbody>
                            <tr onClick={selectBrandHandler}>
                                <td><b>{patients}</b> Patients</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default BrandWisePatientsItemComp;