import React from 'react';
import BrandWisePatientsItemComp from './BrandWisePatientsItemComp';
import configData from '../../Config/Config.json';
import {  calculateMonthlyTotals, findMaxLengthProperty, getBrandPatientsFiscalYear, getBrandWisePatients, getBrandZoneFiscalSummary, getBrandZoneFiscalSummaryMonthOnMonth, getMinMaxOrderMonth, getMonthRange, groupByProperty, groupDataByMonth, orderDataByMonth, parseFiscalYear, sortDataMonthOnMonth } from '../../Service/Common';
import { BrandById, Zone } from '../../Service/AppConstants';
import { Skeleton } from 'primereact/skeleton';
import './BrandWisePatientsComp.css';
import BrandWisePatientPieChart from '../Brand-Wise-Patient-Pie-Chart/BrandWisePatientPieChart';
import BrandLineChart from '../Brand-Line-Chart/BrandLineChart';
import MonthOnMonthLineChart from '../Brand-Line-Chart-M-O-M/MonthOnMonthLineChart';
import { TabView, TabPanel } from 'primereact/tabview';
import BrandZoneDoctorMonthOnMonthPatients from '../Brand-Zone-Doctor-Month-On-Month-Patients/BrandZoneDoctorMonthOnMonthPatients';
import { Button } from 'primereact/button';
import ZoneWiseBarChart from '../Zone-Wise-Bar-Chart/ZoneWiseBarChart';
        

const BrandWisePatientsComp = (props) => {

    const zones = [...Object.values(Zone)];
    const [brandZoneWiseSummary, setbrandZoneWiseSummary] = React.useState([]);
    const [brandPatientsFiscalYear, setBrandPatientsFiscalYear] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isFySelected, setIsFySelected] = React.useState(false);
    const [selectedYearText, setSelectedYearText] = React.useState();
    
    const [isBrandZoneFiscalSummary, setIsBrandZoneFiscalSummary] = React.useState(false);
    const [brandZoneFiscalSummary, setBrandZoneFiscalSummary] = React.useState([]);

    const [brandZoneFiscalSummaryMonthOnMonth, setBrandZoneFiscalSummaryMonthOnMonth] = React.useState();    
    const [selectedMedID, setSelectedMedID] = React.useState(null);

    const [brandDoctorWiseFiscalSummary, setBrandDoctorWiseFiscalSummary] = React.useState();
    const [isBrandDoctorWiseFiscalSummary, setIsBrandDoctorWiseFiscalSummary] = React.useState();

    const [getMonthlyTotals, setMonthlyTotals] = React.useState(null);
    const [months, setMonths] = React.useState({ minMonth: null, maxMonth: null });
    
    const [selectedZone, setSelectedZone] = React.useState(0);

    const [manageMonthOnMonthState, setManageMonthOnMonthState] = React.useState({step:0, selected:null});
    const [selectedDr, setSelectedDr] = React.useState();

    const fySelectedHandler = React.useCallback(async (medId) => {                
        if(medId) {
            setBrandZoneFiscalSummary([]);
            setBrandZoneFiscalSummaryMonthOnMonth({});
            const payLoad = { "MedId": parseInt(medId)};            
            const data = await getBrandPatientsFiscalYear(`${configData.REPORT_URL}/Reports/brandpatientsfiscalyear`, JSON.stringify(payLoad));            
            const filteredData = data && data.filter((item) => item.MedID === medId);            
            setBrandPatientsFiscalYear(filteredData);            
            setIsFySelected(true);
            setIsBrandZoneFiscalSummary(false);
            setSelectedMedID(medId === selectedMedID ? null : medId); 
            setIsBrandDoctorWiseFiscalSummary(false);
        }
    }, []);

    const getSelectedBrandHandler = React.useCallback(async ({selectedBrand}) => {            
      if(selectedBrand.MedID) {
        setBrandZoneFiscalSummary([]);
        setBrandZoneFiscalSummaryMonthOnMonth({});
        const payLoad = { "MedId": parseInt(selectedBrand.MedID)};            
        const data = await getBrandPatientsFiscalYear(`${configData.REPORT_URL}/Reports/brandpatientsfiscalyear`, JSON.stringify(payLoad));            
        const filteredData = data && data.filter((item) => item.MedID === selectedBrand.MedID);            
        setBrandPatientsFiscalYear(filteredData);            
        setIsFySelected(true);
        setIsBrandZoneFiscalSummary(false);
        setSelectedMedID(selectedBrand.MedID === selectedMedID ? null : selectedBrand.MedID); 
        setIsBrandDoctorWiseFiscalSummary(false);
    }
    }, []);

    const brandZoneFiscalSummaryHandler = React.useCallback( async (obj) => {    
        setMonthlyTotals(null);                            
        try {
            const years = parseFiscalYear(obj.FiscalYear);
            const payload = {"BrandID": obj.MedID,"StartYear": years[0],"EndYear": years[1]};            
            await getBrandZoneFiscalSummary(`${configData.REPORT_URL}/Reports/BrandZoneFiscalSummary`, payload, setBrandZoneFiscalSummary);    
            // const respData = await getBrandZoneFiscalSummaryMonthOnMonth(`${configData.REPORT_URL}/Reports/BrandZoneFiscalSummaryMonthOnMonth`, payload);       
            const respData = await getBrandZoneFiscalSummaryMonthOnMonth(`${configData.REPORT_URL}/Reports/BrandZoneFiscalSummaryMonthOnMonth`, payload);      

            const groupedData = groupByProperty(respData, 'ZoneName');        
            setBrandZoneFiscalSummaryMonthOnMonth(groupedData);        

            setSelectedYearText(obj.FiscalYear); 
            setIsBrandDoctorWiseFiscalSummary(false);            
            setMonthlyTotals(orderDataByMonth((calculateMonthlyTotals(groupDataByMonth(groupedData)))));      
                          
          } catch (error) {
            console.error(error.message);
          }
        setIsBrandZoneFiscalSummary(true);        
    }, []);

    const brandDoctorWiseFiscalSummaryHandler = React.useCallback(async (obj) => {                                         
        const years = parseFiscalYear(selectedYearText);
        const payload = {"BrandID": obj.MedID,"StartYear": years[0],"EndYear": years[1], "ZoneId":obj.ZoneId};                    
        // const respData = await getBrandZoneFiscalSummaryMonthOnMonth(`${configData.REPORT_URL}/Reports/BrandDoctorWiseFiscalSummary`, payload);         
        const respData = await getBrandZoneFiscalSummaryMonthOnMonth(`${configData.REPORT_URL}/Reports/BrandDoctorWiseFiscalMonthOnMonthSummary`, payload);         
        setIsBrandDoctorWiseFiscalSummary(true);
        const { minMonth, maxMonth } = getMinMaxOrderMonth(respData);
        setMonths({ minMonth, maxMonth });
        const groupedData = groupByProperty(respData, 'DoctorsId');                             
        setBrandDoctorWiseFiscalSummary(sortDataMonthOnMonth(groupedData));          
    }, [selectedYearText]);

    React.useEffect(() => {
        const fetchData = async () => {
            const payLoad = { "StartDate": "2022-01-01", "EndDate": "2024-12-04"};
            await getBrandWisePatients(`${configData.REPORT_URL}/Reports/BrandPatients`, payLoad, setbrandZoneWiseSummary);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="grid">
                <div className="col-6">
                    {(brandZoneWiseSummary && brandZoneWiseSummary.length > 0) && <BrandWisePatientPieChart data={brandZoneWiseSummary} onClick={getSelectedBrandHandler} />}
                    {isLoading && <Skeleton height="20rem" animation='wave' style={{ width: '200%' }} shape='rectangle'></Skeleton>}
                </div>

                <div className="col-6 flex justify-content-center align-items-center">
                    <div className="grid">

                        <div className="col-12">                            
                            {brandZoneWiseSummary.length > 0 && 
                              <>                                
                                <div className={`top-performer-wrapper product-performer-zone-wise product-performer-zone-wise-modify ${brandZoneWiseSummary.find(item => item.MedID === 37).MedicineName.toLowerCase()}`}>
                                    <b className={`heading ${brandZoneWiseSummary.find(item => item.MedID === 37).MedicineName.toLowerCase()}`}>{brandZoneWiseSummary.find(item => item.MedID === 37).MedicineName.toLowerCase()}</b>
                                    <table className={`${brandZoneWiseSummary.find(item => item.MedID === 37).MedicineName.toLowerCase()}`}>
                                        <tbody>
                                            <tr onClick={() => fySelectedHandler(brandZoneWiseSummary.find(item => item.MedID === 37).MedID)}>
                                                <td><b>{brandZoneWiseSummary.find(item => item.MedID === 37).TotalPatients}</b> Patients</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>                                
                              </>
                            }
                        </div>

                        {!isLoading && brandZoneWiseSummary.map((item, index) => (
                            item.MedID !== 37 &&
                            <BrandWisePatientsItemComp
                                key={index}
                                brandName={item.MedicineName}
                                patients={item.TotalPatients}
                                onClick={() => fySelectedHandler(item.MedID)}
                                isActive={selectedMedID === item.MedID}
                            />
                        ))
                        }                        
                    </div>
                    {isLoading && <Skeleton height="20rem" animation='wave' style={{ width: '200%' }} shape='rectangle'></Skeleton>}
                </div>
            </div>

            {
                (brandPatientsFiscalYear && brandPatientsFiscalYear.length > 0 && isFySelected) &&
                <>
                    <div className="total-patient-year-wise heading"><h2>Financial year {brandPatientsFiscalYear[0].MedicineName} Brand Patients</h2></div>
                    <div className='grid justify-content-center align-items-center'>
                        <div className='financial-year-selected-brand-patients'>
                            <div className='grid justify-content-center'>
                                {brandPatientsFiscalYear.map((item) => (
                                    <div className="col-6" key={item.FiscalYear} onClick={() => brandZoneFiscalSummaryHandler(item)}>
                                        <div className={`top-performer-wrapper product-performer-zone-wise top-performer-wrapper product-performer-zone-wise-financial-modify ${item.MedicineName.toLowerCase()}`}>
                                            <b className={`heading ${item.MedicineName.toLowerCase()}`}>
                                                {item.FiscalYear} {item.MedicineName}
                                            </b>
                                            <table className={item.MedicineName.toLowerCase()}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b>{item.TotalPatients}</b> Patients                                                                                                                     
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='financial-year-selected-brand-patients'>                            
                            <BrandLineChart data={brandPatientsFiscalYear} />
                        </div>
                    </div>
                </>
            }

            {
                (brandZoneFiscalSummary && brandZoneFiscalSummary.length > 0 && isBrandZoneFiscalSummary) && 
                <>                                     
                   <div className="total-patient-year-wise heading"><h2>Zone wise financial year {brandZoneFiscalSummary[0].MedicineName} Brand Patients : {selectedYearText}</h2></div>
                   <div className='total-patient-year-wise overflow-scroll full-width detail'>                        
                        <table>
                            <thead>
                                <tr>
                                    <th>Zone</th>                                    
                                    <th>Medicine Name</th>
                                    <th>Patients</th>                                    
                                    <th>Unique Doctors</th>
                                    <th>Vials/Strips</th>
                                    <th>Total Pap Value</th>
                                </tr>
                            </thead>

                            <tbody>                                                             
                                {brandZoneFiscalSummary.map((item, index) => (
                                    <tr className='tr-white-bg' key={index}>
                                        <td className={item.MedicineName.toLowerCase()}><b>{item.ZoneName}</b></td>
                                        <td className={'pointer'}>{item.MedicineName}</td>
                                        <td className={'pointer'}>{item.TotalPatients}</td>
                                        <td className={'pointer'}>{item.UniqueDoctors}</td>
                                        <td className={'pointer'}>{item.MedicineCount}</td>
                                        <td className={'pointer'}>{item.TotalPapValue}</td>                                                                                
                                    </tr>
                                ))}    
                            </tbody>
                        </table>
                   </div>

                                
                  <ZoneWiseBarChart data={brandZoneFiscalSummary} year={selectedYearText} />              

                </>
            }

            {
                (brandZoneFiscalSummaryMonthOnMonth && Object.keys(brandZoneFiscalSummaryMonthOnMonth).length > 0)  && 
                <div className='tabular-view'>
                    <div className="card">
                        <TabView>

                            <TabPanel header="Table View">
                                <div className='total-patient-year-wise overflow-scroll full-width detail'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Zone</th>
                                                {brandZoneFiscalSummaryMonthOnMonth?.[findMaxLengthProperty(brandZoneFiscalSummaryMonthOnMonth).name]?.length > 0 && brandZoneFiscalSummaryMonthOnMonth[findMaxLengthProperty(brandZoneFiscalSummaryMonthOnMonth).name].map((item, index) => {
                                                    return (<th key={index}>{item.OrderMonthName}</th>)
                                                })}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr className='tr-white-bg'>
                                                <td className='pointer'><b>East</b></td>
                                                {brandZoneFiscalSummaryMonthOnMonth?.['EAST']?.length > 0 && brandZoneFiscalSummaryMonthOnMonth['EAST'].map((item, index) => {
                                                    return (
                                                        <td key={index} className='pointer' onClick={() => brandDoctorWiseFiscalSummaryHandler(item)}>
                                                            <div>Patients : <b>{item.TotalPatients}</b></div>
                                                            <div>Vials : <b>{item.MedicineCount}</b></div>
                                                            <div>UniqueDoctors : <b>{item.UniqueDoctors}</b></div>
                                                            <div>Pap : <b>{item.TotalPapValue}</b></div>
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                            <tr className='tr-white-bg'>
                                                <td className='pointer'><b>North</b></td>
                                                {brandZoneFiscalSummaryMonthOnMonth?.['NORTH']?.length > 0 && brandZoneFiscalSummaryMonthOnMonth['NORTH'].map((item, index) => {
                                                    return (
                                                        <td key={index} className='pointer' onClick={() => brandDoctorWiseFiscalSummaryHandler(item)}>
                                                            <div>Patients : <b>{item.TotalPatients}</b></div>
                                                            <div>Vials : <b>{item.MedicineCount}</b></div>
                                                            <div>UniqueDoctors : <b>{item.UniqueDoctors}</b></div>
                                                            <div>Pap : <b>{item.TotalPapValue}</b></div>
                                                        </td>
                                                    )
                                                })}
                                            </tr>

                                            <tr className='tr-white-bg'>
                                                <td className='pointer'><b>South</b></td>
                                                {brandZoneFiscalSummaryMonthOnMonth?.['SOUTH']?.length > 0 && brandZoneFiscalSummaryMonthOnMonth['SOUTH'].map((item, index) => {
                                                    return (
                                                        <td key={index} className='pointer' onClick={() => brandDoctorWiseFiscalSummaryHandler(item)}>
                                                            <div>Patients : <b>{item.TotalPatients}</b></div>
                                                            <div>Vials : <b>{item.MedicineCount}</b></div>
                                                            <div>UniqueDoctors : <b>{item.UniqueDoctors}</b></div>
                                                            <div>Pap : <b>{item.TotalPapValue}</b></div>
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                            <tr className='tr-white-bg'>
                                                <td className='pointer'><b>West</b></td>
                                                {brandZoneFiscalSummaryMonthOnMonth?.['WEST']?.length > 0 && brandZoneFiscalSummaryMonthOnMonth['WEST'].map((item, index) => {
                                                    return (
                                                        <td key={index} className='pointer' onClick={() => brandDoctorWiseFiscalSummaryHandler(item)}>
                                                            <div>Patients : <b>{item.TotalPatients}</b></div>
                                                            <div>Vials : <b>{item.MedicineCount}</b></div>
                                                            <div>UniqueDoctors : <b>{item.UniqueDoctors}</b></div>
                                                            <div>Pap : <b>{item.TotalPapValue}</b></div>
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </TabPanel>

                            <TabPanel header="Chart View">

                                    {manageMonthOnMonthState.step === 0 && <div className='choose-option-report'>
                                        <Button label="Medicine" severity="secondary" onClick={() => {setManageMonthOnMonthState({step: 1}); setSelectedZone(0)}} />
                                        <Button label="Patient" severity="secondary" onClick={() => {setManageMonthOnMonthState({step: 2}); setSelectedZone(0)}}  />
                                        <Button label="Doctor" severity="secondary" onClick={() => {setManageMonthOnMonthState({step: 3}); setSelectedZone(0)}} />
                                        <Button label="Pap" severity="secondary" onClick={() => {setManageMonthOnMonthState({step: 4}); setSelectedZone(0)}} />                                        
                                    </div>}

                                    {manageMonthOnMonthState.step === 1 && <div className='month-on-month-data-wrapper' id='monthOnMonthMedicine'>                                        
                                        <div className='zone-button-inline'>                                            
                                            <Button label="Back" severity="secondary" className='back-btn' outlined  onClick={() => setManageMonthOnMonthState({step: 0})} />
                                            <Button label="All" severity="secondary" onClick={() => setSelectedZone(0)} className={selectedZone === 0 ? 'active' : ''} />
                                            <Button label="East" severity="secondary" onClick={() => setSelectedZone(1001)} className={selectedZone === 1001 ? 'active' : ''} />
                                            <Button label="North" severity="secondary" onClick={() => setSelectedZone(1002)} className={selectedZone === 1002 ? 'active' : ''} />
                                            <Button label="South" severity="secondary" onClick={() => setSelectedZone(1003)} className={selectedZone === 1003 ? 'active' : ''} />
                                            <Button label="West" severity="secondary" onClick={() => setSelectedZone(1000)} className={selectedZone === 1000 ? 'active' : ''} />
                                        </div>
                                        <MonthOnMonthLineChart BrandID={selectedMedID} ZoneId={selectedZone} url={'BrandZoneSummaryMonthOnMonthMedicine'} title={'Month On Month Medicine Report'} />
                                    </div>}

                                    {manageMonthOnMonthState.step === 2 && <div className='month-on-month-data-wrapper' id='monthOnMonthPatient'>
                                        <div className='zone-button-inline'>           
                                            <Button label="Back" severity="secondary" className='back-btn' outlined  onClick={() => setManageMonthOnMonthState({step: 0})} />                                 
                                            <Button label="All" severity="secondary" onClick={() => setSelectedZone(0)} className={selectedZone === 0 ? 'active' : ''} />
                                            <Button label="East" severity="secondary" onClick={() => setSelectedZone(1001)} className={selectedZone === 1001 ? 'active' : ''} />
                                            <Button label="North" severity="secondary" onClick={() => setSelectedZone(1002)} className={selectedZone === 1002 ? 'active' : ''} />
                                            <Button label="South" severity="secondary" onClick={() => setSelectedZone(1003)} className={selectedZone === 1003 ? 'active' : ''} />
                                            <Button label="West" severity="secondary" onClick={() => setSelectedZone(1000)} className={selectedZone === 1000 ? 'active' : ''} />
                                        </div>
                                        <MonthOnMonthLineChart BrandID={selectedMedID} ZoneId={selectedZone} url={'BrandZoneSummaryMonthOnMonthPatient'} title={'Month On Month Patient Report'} />
                                    </div>}

                                    {manageMonthOnMonthState.step === 3 && <div className='month-on-month-data-wrapper' id='monthOnMonthDoctor'>
                                        <div className='zone-button-inline'>    
                                            <Button label="Back" severity="secondary" className='back-btn' outlined  onClick={() => setManageMonthOnMonthState({step: 0})} />                                        
                                            <Button label="All" severity="secondary" onClick={() => setSelectedZone(0)} className={selectedZone === 0 ? 'active' : ''} />
                                            <Button label="East" severity="secondary" onClick={() => setSelectedZone(1001)} className={selectedZone === 1001 ? 'active' : ''} />
                                            <Button label="North" severity="secondary" onClick={() => setSelectedZone(1002)} className={selectedZone === 1002 ? 'active' : ''} />
                                            <Button label="South" severity="secondary" onClick={() => setSelectedZone(1003)} className={selectedZone === 1003 ? 'active' : ''} />
                                            <Button label="West" severity="secondary" onClick={() => setSelectedZone(1000)} className={selectedZone === 1000 ? 'active' : ''} />
                                        </div>
                                        <MonthOnMonthLineChart BrandID={selectedMedID} ZoneId={selectedZone} url={'BrandZoneSummaryMonthOnMonthDoctor'} title={'Month On Month Doctor Report'} />
                                    </div>}


                                    {manageMonthOnMonthState.step === 4 && <div className='month-on-month-data-wrapper' id='monthOnMonthPap'>
                                        <div className='zone-button-inline'>   
                                            <Button label="Back" severity="secondary" className='back-btn' outlined  onClick={() => setManageMonthOnMonthState({step: 0})} />                                         
                                            <Button label="All" severity="secondary" onClick={() => setSelectedZone(0)} className={selectedZone === 0 ? 'active' : ''} />
                                            <Button label="East" severity="secondary" onClick={() => setSelectedZone(1001)} className={selectedZone === 1001 ? 'active' : ''} />
                                            <Button label="North" severity="secondary" onClick={() => setSelectedZone(1002)} className={selectedZone === 1002 ? 'active' : ''} />
                                            <Button label="South" severity="secondary" onClick={() => setSelectedZone(1003)} className={selectedZone === 1003 ? 'active' : ''} />
                                            <Button label="West" severity="secondary" onClick={() => setSelectedZone(1000)} className={selectedZone === 1000 ? 'active' : ''} />
                                        </div>
                                        <MonthOnMonthLineChart BrandID={selectedMedID} ZoneId={selectedZone} url={'BrandZoneSummaryMonthOnMonthPapValue'} title={'Month On Month Pap Report'} />
                                    </div>}


                                    {/* <DrPatientProgressColumnChart  /> */}


                            </TabPanel>

                        </TabView>
                    </div>
                </div>
            }

            {                
                (brandDoctorWiseFiscalSummary && isBrandDoctorWiseFiscalSummary) &&
                <>
                    
                    {selectedDr && <>
                            <div className="total-patient-year-wise heading">
                                <h2 className='dr-detial-btn-headin'>
                                    <Button icon="pi pi-arrow-left" label="" raised onClick={() => setSelectedDr(null)} className='dr-detial-btn-btn' />
                                    <span>Dr {selectedDr.DoctorsName} {selectedDr.ZoneName} Zone  Brand {BrandById[selectedDr['MedID']]}</span> 
                                </h2>
                            </div>
                            <BrandZoneDoctorMonthOnMonthPatients selectedDr={selectedDr} />
                        </>}
                    { !selectedDr &&
                        <>
                            <div className="total-patient-year-wise heading"><h2>Financial {selectedYearText} {brandZoneFiscalSummary[0].MedicineName} Doctor Performance</h2></div>
                            <div className='total-patient-year-wise overflow-scroll full-width detail'>
                                <table className='total-dr-month-on-month-table'>
                                    <thead>
                                        <tr>
                                            <th>Dr Name & Code</th>
                                            {getMonthRange(0, 11).map((item, index) => (
                                                <th key={index}>{item}</th>
                                            ))}
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.keys(brandDoctorWiseFiscalSummary).map(doctorId => (
                                            <tr className='tr-white-bg' key={doctorId}>
                                                <td>
                                                    <div className='dr-report-wrapper'>
                                                        <span className='dr-drid-text'><b>{brandDoctorWiseFiscalSummary[doctorId][0]['DoctorsName']}</b> : {doctorId}</span>
                                                        {/* <Button label="" icon="pi pi-eye" size="small" onClick={() => setSelectedDr(brandDoctorWiseFiscalSummary[doctorId][0])} /> */}
                                                        <span className='btn' onClick={() => setSelectedDr(brandDoctorWiseFiscalSummary[doctorId][0])}>View</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'January').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'January' && <>
                                                                <div className='dr-wise-month-on-month-data'>
                                                                    <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                    <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                    <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                                </div>
                                                            </>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'February').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'February' && <>
                                                                <div className='dr-wise-month-on-month-data'>
                                                                    <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                    <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                    <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                                </div>
                                                            </>}

                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'March').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'March' && <>
                                                                <div className='dr-wise-month-on-month-data'>
                                                                    <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                    <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                    <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                                </div>
                                                            </>}

                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'April').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'April' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div> </>}

                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'May').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'May' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div> </>}
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'June').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'June' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div> </>}
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'July').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'July' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div></>}
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'August').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'August' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div></>}
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'September').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'September' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div> </>}
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'October').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'October' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div> </>}
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'November').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'November' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div> </>}
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                <td>
                                                    {brandDoctorWiseFiscalSummary[doctorId].filter(item => item.OrderMonthName === 'December').length === 0 && ''}
                                                    {brandDoctorWiseFiscalSummary[doctorId].map((order, index) => (
                                                        <React.Fragment key={index}>
                                                            {order.OrderMonthName === 'December' && <><div className='dr-wise-month-on-month-data'>
                                                                <div>Month Name : <b>{order.OrderMonthName}</b></div>
                                                                <div>Total Pap Value  : <b>{order.TotalPapValue}</b></div>
                                                                <div>Total Patients  : <b>{order.TotalPatients}</b></div>
                                                            </div> </>}
                                                        </React.Fragment>
                                                    ))}
                                                </td>
                                                {/* <td><Button label="" icon="pi pi-eye" size="small" onClick={() => setSelectedDr(brandDoctorWiseFiscalSummary[doctorId][0])} /></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                </>                
            }

        </>
    );
};

export default BrandWisePatientsComp;