import React from 'react';
import './ZonePerformanceByBrand.scss';
import ZonePerformanceByBrandItem from './ZonePerformanceByBrandItem';
import configData from '../../Config/Config.json';
import { getBrandPerfomance } from '../../Service/Common';
import { Brand, Zone } from '../../Service/AppConstants';
import { Skeleton } from 'primereact/skeleton';


const ZonePerformanceByBrand = (props) => {


    const zones = [...Object.values(Zone)];
    const [brandZoneWiseSummary, setbrandZoneWiseSummary] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchData = async () => {
            await getBrandPerfomance(`${configData.REPORT_URL}/Reports/ZoneBrandSummary`, setbrandZoneWiseSummary);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    return (
        <div className="grid">
            {!isLoading && brandZoneWiseSummary.map((item, index) => (            
                    <ZonePerformanceByBrandItem
                        key={index}
                        zoneName={zones[index % zones.length]}
                        brandName={item.MedicineName}
                        north={item.North}
                        east={item.East}
                        south={item.South}
                        west={item.West}                            
                    />
            ))
            }

            {
                isLoading &&
                Array.from({ length: 4 }, (_, index) => (
                    <div className="col-3" key={index}><Skeleton height="10rem" animation='wave' style={{ width: '200%' }} shape='rectangle'></Skeleton></div>
                ))
            }

        </div>
    );
};

export default React.memo(ZonePerformanceByBrand);


