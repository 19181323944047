import React from 'react';
import ApexCharts from 'react-apexcharts';
import './BrandZoneDoctorMonthOnMonthPatients.css';
import { getBrandZoneDoctorMonthOnMonthPatients } from '../../Service/Common';
import configData from '../../Config/Config.json';

const BrandZoneDoctorMonthOnMonthPatients = (props) => {

  const [seriedData, setSeriesData] = React.useState([]);

  const options = {
    chart: {
      type: 'bar', 
      height: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true, // Makes the bars horizontal
      },
    },
    xaxis: {
      categories: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    },    
    legend: {
      position: 'top', // Position of the legend
    },
    title: {
        text: `Brand Zone Doctor Month On Month Patients`, // Updated title
        align: 'center', // Title alignment
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      }       
  };

    React.useEffect(() => {

      const getLoadData = async () => {
        const payload = {
          "BrandID": props.selectedDr.MedID,
          "StartYear": new Date().getFullYear(props.selectedDr.OrderYear),
          "EndYear": new Date().getFullYear(props.selectedDr.OrderYear) - 1,
          "ZoneId":props.selectedDr.ZoneId,
          "DoctorId":props.selectedDr.DoctorsId,
          "DoctorName":props.selectedDr.DoctorsName
        }
        const respData = await getBrandZoneDoctorMonthOnMonthPatients(`${configData.REPORT_URL}/Reports/BrandZoneDoctorMonthOnMonthPatients`, payload); 
        
        let year2022Data = new Array(12).fill(null);  // Array of 12 months filled with null
        let year2023Data = new Array(12).fill(null);  // Array of 12 months filled with null
        let year2024Data = new Array(12).fill(null);  // Array of 12 months filled with null

        respData.forEach(item => {
          const monthIndex = item.OrderMonth - 1;  
          if (item["2022"] !== null) {
            year2022Data[monthIndex] = item["2022"];
          }
          if (item["2023"] !== null) {
              year2023Data[monthIndex] = item["2023"];
          }
          if (item["2024"] !== null) {
              year2024Data[monthIndex] = item["2024"];
          }         
        });

        const seriesDataList = [
          {
            name: '2022',
            data: [...year2022Data.map(item => item === undefined ? null : item)],
            color: '#4C585B'
          },
          {
            name: '2023',
            data: [...year2023Data.map(item => item === undefined ? null : item).map(item => item === undefined ? null : item)],
            color: '#7E99A3'
          },
          {
            name: '2024',
            data: [...year2024Data.map(item => item === undefined ? null : item)],
            color: '#A5BFCC'
          }         
        ];
        setSeriesData(seriesDataList);
        console.log('series', seriedData);
      };

      getLoadData();
      
    }, [props, props.selectedDr]);

  return (
    <div id='BrandZoneDoctorMonthOnMonthPatients'>
      <ApexCharts options={options} series={seriedData} type="bar" height={600} />
    </div>
  );
};

export default BrandZoneDoctorMonthOnMonthPatients;
