import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { generateColorArray, getBrandZoneSummaryMonthOnMonthMedicine } from '../../Service/Common';
import './MonthOnMonthLineChart.css';
import { ChartBackground } from '../../Service/AppConstants';
import configData from '../../Config/Config.json';

const MonthOnMonthLineChart = (props) => {

    const [seriesData, setSeriesData] = React.useState([]);
    const [range, setRange] = React.useState({ min: 0, max: 0 });
    
    React.useEffect(() => {   

        console.log('props.BrandID', props.BrandID);
        console.log('props.ZoneId', props.ZoneId);
        
        const getData = async () => {
            const payload = { "BrandID": props.BrandID, "StartYear": 2023, "EndYear": new Date().getFullYear(), "ZoneId": props.ZoneId ? props.ZoneId : 0 };
            const respData = await getBrandZoneSummaryMonthOnMonthMedicine(`${configData.REPORT_URL}/Reports/${props.url}`, payload);
            console.log('data', respData);
            
            const years = Object.keys(respData[0]).filter(key => !isNaN(key));
            const series = years.map(year => ({
                name: `${year}`,
                data: Array.from({ length: 12 }, (_, i) => {
                    const item = respData.find(r => r.OrderMonth === i + 1);                    
                    return item ? (item[year] === null ? 0 : item[year]) : 0;
                }),
            }));            
            setSeriesData(series);

            const allValues = respData.flatMap(item => [item["2023"] ?? 0, item[new Date().getFullYear()] ?? 0]);
            const min = Math.min(...allValues);
            const max = Math.max(...allValues);            
            setRange({ min, max });
        };

        getData();
        
    }, [props.BrandID, props.ZoneId, props.url]);

    // const series = [
    //     {
    //         name: "2023 Sales",
    //         data: [30, 40, 35, 50, 49, 60, 70, 50, 78, 95, 56, 65], 
    //     },
    //     {
    //         name: "2024 Sales",
    //         data: [25, 45, 30, 55, 60, 65, 80, 45, 80, 98, 25, 14], 
    //     }
    // ];

    const options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            events: {
                click: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {                    
                }
            },
            background: ChartBackground,
            dropShadow: {
                enabled: true
            }, 
            padding: {
                top: 20,
                right: 20,
                bottom: 30,
                left: 40,
              },      
        },

        colors: ["blue", "orange"],

        legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center', 
            floating: false,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: '#fff',
                useSeriesColors: false
            },
            markers: {
                size: 7,
                shape: undefined,
                strokeWidth: 1,
                fillColors: undefined,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 20,
                vertical: 10
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: '#feb019', 
            width: 2,
        },
        title: {
            text: props.title,
            align: 'center',
            style: { fontWeight: 500, color: '#fff', fontSize: 12 },
            offsetY: 15
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
            row: {
                colors: ['transparent', 'transparent'],
                opacity: 0
            },
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            labels: {
                style: {
                    colors: [...generateColorArray(12, '#ffffff')],
                    fontSize: '12px',
                },
                offsetX: 0,
                offsetY: 0,
            }
        },
        yaxis: {
            title: {
                text: ''
            },
            //tickAmount: 100,
            min: range.min,
            max: range.max * 2,
            labels: {
                style: {
                    colors: ['#ffffff'],
                    fontSize: '12px',
                },                
                offsetX: 0, 
                offsetY: 0,
                formatter: (value) => Math.round(value),
            },
        },
    };

    return (
        <>
            <div id="BrandLineChartMOM">
                <ReactApexChart options={options} series={seriesData} width={'100%'} height={400} />
            </div>
        </>
    );
};

export default MonthOnMonthLineChart;
