import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';
import axios from "axios";
import configData from '../../../Config/Config.json';
import { formatDate, getCurrentFinancialYearData, getCurrentFinancialYearRange, getFirstLastDateOfTheYear, getKamDataBasedOnTheMonthZoneWise, getKamDetailByZoneStartEndDate, getKamSelectedByZoneYear, getLastDayOfMonth, getMonthlyDateRanges, getMonthName, getSelectedZone } from '../../../Service/Common';
import { json, useLocation, useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const months = [
    { name: 'January', code: 0 },
    { name: 'February', code: 1 },
    { name: 'March', code: 2 },
    { name: 'April', code: 3 },
    { name: 'May', code: 4 },
    { name: 'June', code: 5 },
    { name: 'July', code: 6 },
    { name: 'August', code: 7 },
    { name: 'September', code: 8 },
    { name: 'October', code: 9 },
    { name: 'November', code: 10 },
    { name: 'December', code: 11 }
];

const PatientDashboard = () => {

    const totalPatientsYearWiseUrl = `${configData.REPORT_URL}/Reports/TotalPatientsYearWise`;
    const zoneSummaryUrl = `${configData.REPORT_URL}/Reports/ZoneSummary`;

    const [totalPatientsYearWise, setTotalPatientsYearWise] = useState([]);
    const [zoneSummary, setZoneSummary] = useState([]);
    const query = useQuery();
    const patient = query.get('patient');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedKamZoneWise, setSelectedKamZoneWise] = useState([]);
    const [selectedZoneId, setSelectedZoneId] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [eastDataList, setEastDataList] = useState([]);
    const [northDataList, setNorthDataList] = useState([]);
    const [southDataList, setSouthDataList] = useState([]);
    const [westDataList, setWestDataList] = useState([]);
    const [kamDetailList, setKamDetailList] = useState([]);
    const [month, setMonth] = useState([]);
    const [selectedZoneName, setSelectedZoneName] = useState(null);
     

    const [test, setTest] = useState(null);

    const onYearWiseDataLoadHandler = async (e) => {

        const years = [2022, 2023, 2024];
        const responseArrData = [];

        for (const year of years) {
            const paramObj = { "Year": year };
            try {
                const resp = await axios.post(totalPatientsYearWiseUrl, paramObj);
                setLoader(true);
                if (resp.status === 200) {
                    responseArrData.push(resp.data.Data);
                    setLoader(false);
                }
            } catch (error) {
                console.error(`Error for year ${year}:`, error);
            }
        }
        setTotalPatientsYearWise(responseArrData.flat());
    }

    const getDataZoneWise = async (year) => {

        setLoader(true);
        setSelectedYear(0);
        setEastDataList([]);
        setNorthDataList([]);
        setSouthDataList([]);
        setWestDataList([]);
        setMonth([]);

        const paramObj = {
            "StartDate": formatDate(getFirstLastDateOfTheYear(year).firstDayOfYear),
            "EndDate": formatDate(getFirstLastDateOfTheYear(year).lastDayOfYear)
        };

        

        const {StartDate} = getCurrentFinancialYearRange();
        const  dateRange  =  getMonthlyDateRanges({StartDate});
        const getCurrentDateRangeTillNow = getCurrentFinancialYearData(dateRange);

        const monthlyDateRanges =  getCurrentDateRangeTillNow //getMonthlyDateRanges(paramObj);

        try {
            const promises = monthlyDateRanges.map((monthRange) => {
                const requestBody = { "StartDate": monthRange.StartDate, "EndDate": monthRange.EndDate };
                return axios.post(zoneSummaryUrl, requestBody);
            });

            const responses = await Promise.all(promises);
            const allData = responses.filter(resp => resp.status === 200).map(resp => resp.data.Data);
            

            if (responses[0].status === 200) {
                console.log('allData', allData);

                const eastData = [];
                const northData = [];
                const southData = [];
                const westData = [];

                allData.forEach((weekData, index) => {
                    weekData.forEach(entry => {
                        if (entry) {
                            switch (entry.ZoneName) {
                                case "EAST":
                                    eastData.push({ ...entry, Month: getMonthName(index), ...monthlyDateRanges[index] });
                                    break;
                                case "NORTH":
                                    northData.push({ ...entry, Month: getMonthName(index), ...monthlyDateRanges[index] });
                                    break;
                                case "SOUTH":
                                    southData.push({ ...entry, Month: getMonthName(index), ...monthlyDateRanges[index] });
                                    break;
                                case "WEST":
                                    westData.push({ ...entry, Month: getMonthName(index), ...monthlyDateRanges[index] });
                                    break;
                            }
                        }
                    });
                });

                setEastDataList(eastData);
                setNorthDataList(northData);
                setSouthDataList(southData);
                setWestDataList(westData);

                setZoneSummary(allData);
                setLoader(false);
                setSelectedYear(year);

            }

        } catch (error) {
            console.error(`Error for year ${year}:`, error);
        }

    };

    const getSelectedZoneId = async (zoneId) => {

        const paramObj = {
            "StartDate": formatDate(getFirstLastDateOfTheYear(selectedYear).firstDayOfYear),
            "EndDate": formatDate(getFirstLastDateOfTheYear(selectedYear).lastDayOfYear),
            "ZoneID": zoneId
        };

        await getKamSelectedByZoneYear(`${configData.REPORT_URL}/Reports/EmpSummary`, paramObj, setSelectedKamZoneWise);
        setSelectedZoneId(zoneId);
        setIsDropdownVisible(true);

    };

    const monthSelectHandler = async (event) => {

        const selectedMon = event.value;
        setSelectedMonth(selectedMon);

        const year = new Date().getFullYear();
        const startDate = new Date(year, selectedMon.code, 1);
        const endDate = new Date(year, selectedMon.code + 1, 0);

        setTest({ startDate: formatDate(startDate), endDate: formatDate(endDate), selectedZoneId: selectedZoneId });

        const paramObj = {
            "StartDate": formatDate(startDate),
            "EndDate": formatDate(endDate),
            "ZoneID": selectedZoneId
        };

        await getKamSelectedByZoneYear(`${configData.REPORT_URL}/Reports/EmpSummary`, paramObj, setSelectedKamZoneWise);
    };

    const getKamDetailZoneWise = async (obj) => {

        setLoader(true);
        setMonth([]);
        console.log('obj', obj);
        setSelectedZoneName(obj.ZoneName);
        

        const {StartDate, EndDate} = getCurrentFinancialYearRange();
        const  dateRange  =  getMonthlyDateRanges({StartDate});

        const getCurrentDateRangeTillNow = getCurrentFinancialYearData(dateRange);
        const resp = getKamDataBasedOnTheMonthZoneWise(getCurrentDateRangeTillNow, obj.ZoneId);

        resp.then((allData) => {
            const tableDataList = {};
            const monthDataList = allData.map(month => month.MonthName);
            console.log('allData', allData);

            allData.forEach(monthData => {
                const monthName = monthData.MonthName;
        
                monthData.data.forEach(employee => {
                    const { employeename, TotalPatients, Totalstrips, TotalPapValue, TotalVials } = employee;
                    
                    if (!tableDataList[employeename]) {
                        tableDataList[employeename] = {};
                    }
                    
                    tableDataList[employeename][monthName] = { TotalPatients, Totalstrips, TotalPapValue, TotalVials };
                });
            });

            setKamDetailList(tableDataList);
            setMonth(monthDataList);
            setLoader(false);

        }).catch((error) => {
            console.error("Error fetching data:", error);
        });



    };

    useEffect(() => {
        onYearWiseDataLoadHandler();
        return () => console.log('');
    }, [patient]);


    return (
        <div className='patient-main-content'>

            <div className='flex align-content-center same-detail-header'>
                <div className="back-btn flex align-items-center">
                    <img alt="logo" src={process.env.PUBLIC_URL + '/back-button.png'} onClick={() => navigate('/user-dashboard')} />
                </div>
                <div className="box-shadow patients-dr-wrapper patient-header patient-detail-header">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <b>No. of Patients</b>
                                    <span>{patient}</span>
                                </td>
                                <td>
                                    <img src={process.env.PUBLIC_URL + '/img/patient.png'} alt="React Logo" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='patient-dashboard-wrapper'>

                    <div className='total-patient-year-wise'>
                    {totalPatientsYearWise.length > 0 &&
                        <table>
                            <thead>
                                <tr>
                                    {totalPatientsYearWise.map((item, index) => {
                                        return (
                                            <th key={index}>FY {item.Year}-{new Date(item.Year, new Date().getMonth()).getFullYear() + 1}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {totalPatientsYearWise.map((item, index) => {
                                        return (
                                            <td key={index} onClick={() => parseInt(item.Year) === new Date().getFullYear() ? getDataZoneWise(item.Year) : null} className={parseInt(item.Year) === new Date().getFullYear() ? 'pointer' : ''}>                                                
                                                {parseInt(item.Year) === new Date().getFullYear() ? <Button className='grey-btn' label={item.TotalPatients} onClick={() => getDataZoneWise(item.Year)} /> : item.TotalPatients }                                      
                                            </td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                        }
                        {totalPatientsYearWise.length === 0 && <Skeleton height="5rem" animation='wave' shape='rectangle' className='kam-dashboard-table-skelton'></Skeleton>}
                    </div>
                

                  {selectedYear !== 0 && <div className='total-patient-year-wise heading'><h2>Selected Year : {selectedYear.toString()}</h2></div>}

                  {(selectedYear === 0 && loader) && 
                     <div className='total-patient-year-wise heading'>                                              
                      <Skeleton height="2rem" animation='wave' shape='rectangle' className='kam-dashboard-table-skelton' width={250}></Skeleton>
                     </div>
                  }


                  {
                    (eastDataList.length === 0 && westDataList.length === 0 && northDataList.length === 0 && southDataList.length === 0 && loader) && 
                    <div className='total-patient-year-wise overflow-scroll full-width detail'>
                        <Skeleton height="30rem" animation='wave' shape='rectangle' className='kam-dashboard-table-skelton'></Skeleton>                       
                    </div>  
                  }
                   
                    
                {
                    eastDataList.length > 0 &&
                    <div className='total-patient-year-wise overflow-scroll full-width detail'>                        
                        <table>
                            <thead>
                                <tr>
                                    <th>Zone</th>                                    
                                    {eastDataList.map((item, index) => {
                                        return (<th key={index}>{item.MonthName}</th>)
                                    })}
                                </tr>
                            </thead>

                            <tbody>
                                <tr className='tr-white-bg'>
                                    <td className='east'><b>East</b></td>
                                    {eastDataList.map((item, index) => {
                                        return (
                                            <td key={index} onClick={() => getKamDetailZoneWise(item)} className='pointer east-bg'>
                                                <div>Patients : <b>{item.TotalPatients}</b></div>
                                                <div>Vials : <b>{item.TotalVials}</b></div>
                                                <div>Strips : <b>{item.Totalstrips}</b></div>
                                                <div>Pap : <b>{item.TotalPapValue}</b></div>
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr className='tr-white-bg'>
                                    <td className='north'><b>North</b></td>
                                    {northDataList.map((item, index) => {
                                        return (
                                            <td key={index} onClick={() => getKamDetailZoneWise(item)} className='pointer north-bg'>
                                                <div>Patients : <b>{item.TotalPatients}</b></div>
                                                <div>Vials : <b>{item.TotalVials}</b></div>
                                                <div>Strips : <b>{item.Totalstrips}</b></div>
                                                <div>Pap : <b>{item.TotalPapValue}</b></div>
                                            </td>
                                        )
                                    })}
                                </tr>

                                <tr className='tr-white-bg'>
                                    <td className='south'><b>South</b></td>
                                    {southDataList.map((item, index) => {
                                        return (
                                            <td key={index} onClick={() => getKamDetailZoneWise(item)} className='pointer south-bg'>
                                                <div>Patients : <b>{item.TotalPatients}</b></div>
                                                <div>Vials : <b>{item.TotalVials}</b></div>
                                                <div>Strips : <b>{item.Totalstrips}</b></div>
                                                <div>Pap : <b>{item.TotalPapValue}</b></div>
                                            </td>
                                        )
                                    })}
                                </tr>

                                <tr className='tr-white-bg'>
                                    <td className='west'><b>West</b></td>
                                    {westDataList.map((item, index) => {
                                        return (
                                            <td key={index} onClick={() => getKamDetailZoneWise(item)} className='pointer west-bg'>
                                                <div>Patients : <b>{item.TotalPatients}</b></div>
                                                <div>Vials : <b>{item.TotalVials}</b></div>
                                                <div>Strips : <b>{item.Totalstrips}</b></div>
                                                <div>Pap : <b>{item.TotalPapValue}</b></div>
                                            </td>
                                        )
                                    })}
                                </tr>

                            </tbody>
                        </table>
                    </div>
                }

                {(month.length > 0 && !loader) && <div className='total-patient-year-wise heading'><h2>Selected Zone : {selectedZoneName}</h2></div>}
                
                {(month.length === 0 && loader) &&
                   <div className='total-patient-year-wise heading'>                                              
                    <Skeleton height="2rem" animation='wave' shape='rectangle' className='kam-dashboard-table-skelton' width={250}></Skeleton>
                   </div>
                }

                {(month.length === 0 && loader) &&
                  <div className='total-patient-year-wise overflow-scroll full-width detail'>
                   <Skeleton height="20rem" animation='wave' shape='rectangle' className='kam-dashboard-table-skelton'></Skeleton>  
                  </div>
                }
                
                { (month.length > 0 && !loader) &&
                    <div className='total-patient-year-wise overflow-scroll full-width detail'>
                        <table border="1">
                            <thead>
                                <tr>
                                    <th>Kam</th>
                                    {month.map(month => (<th key={month}>{month}</th>))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(kamDetailList).map((employeeName, indx) => (
                                    <tr key={indx}>
                                        <td>{employeeName}</td>
                                        {month.map(month => (
                                            <td key={month}>{kamDetailList[employeeName][month] ? (
                                                <>
                                                    <div>Total Patients: <b>{kamDetailList[employeeName][month].TotalPatients}</b></div>
                                                    <div>Total Strips: <b>{kamDetailList[employeeName][month].Totalstrips}</b></div>
                                                    <div>Total Pap Value: <b>{kamDetailList[employeeName][month].TotalPapValue}</b></div>
                                                    <div>Total Vials: <b>{kamDetailList[employeeName][month].TotalVials}</b></div>
                                                </>
                                            ) : ("No Data")}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }

            </div>

            {isDropdownVisible > 0 &&

                <div className='total-patient-year-wise heading'>
                    <div className='flex justify-content-between align-items-center'>
                        <h2>Selected Zone : {getSelectedZone(selectedZoneId)}</h2>
                        <Dropdown value={selectedMonth} onChange={monthSelectHandler} options={months} optionLabel="name" placeholder="Select a Month" />
                    </div>
                </div>
            }

            {selectedKamZoneWise.length > 0 &&
                <div className='kam-main-content p-0'>
                    <div className='kam-dashboard-wrapper'>
                        <div className='table-data flex align-items-start justify-content-center'>
                            <DataTable value={selectedKamZoneWise} tableStyle={{ minWidth: '50rem' }}>
                                <Column field="employeename" header="Kam"></Column>
                                <Column field="TotalPatients" header="Patients"></Column>
                                <Column field="TotalVials" header="Vials"></Column>
                                <Column field="TotalPapValue" header="Pap"></Column>
                                <Column field="Totalstrips" header="Strips"></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
};

export default PatientDashboard;