import React, { useRef, useState, useEffect } from 'react';
import './AdminDashboard.css';
import DrPatientMonthColumnChart from '../../../Component/Dr-Patient-Month-Column-Chart/ColumnChart';
import LineChart from '../../../Component/Pap-Line-Chart/LineChart';
import PieChart from '../../../Component/Zone-Wise-Pie-Chart/PieChart';
import html2canvas from 'html2canvas';
import { Calendar } from 'primereact/calendar';
import { groupByKey, getIndicationText, formatDate } from '../../../Service/Common';
import axios from "axios";
import configData from '../../../Config/Config.json';
import { Button } from 'primereact/button';
import ZonePerformanceByKam from '../../../Component/ZonePerformanceByKam/ZonePerformanceByKam';
import ZonePerformanceByBrand from '../../../Component/ZonePerformanceByBrand/ZonePerformanceByBrand';
import BrandWisePieChartComparison from '../../../Component/Brand-Wise-Pie-Chart-Comparison/BrandWisePieChartComparison';
import PapZoneWisePieChartComparison from '../../../Component/Pap-Zone-Wise-Pie-Chart-Comparison/PapZoneWisePieChartComparison';
import PatientZoneWisePieChartComparison from '../../../Component/Patient-Zone-Wise-Pie-Chart-Comparison/PatientZoneWisePieChartComparison';
import PanIndiaDrPatient from '../../../Component/PanIndiaDrPatient/PanIndiaDrPatient';
import ColumnChart from '../../../Component/Medicine-Usage-Summary-Column-Chart/ColumnChart';




const UserDashboard = () => {

    const url = `${configData.SERVER_URL}/home/KamAdminReport`;
    const tdrUrl = `${configData.SERVER_URL}/home/KamMedicineTDR`;


    const exportPDF = () => {

    }

    useEffect(() => {

        const loadSummaryData = async () => {
            await axios.post(url);
        };

        const loadTdrData = async () => {
            await axios.post(tdrUrl);
        };

        loadSummaryData();
        loadTdrData();


    }, []);


    const filterUrl = `${configData.SERVER_URL}/home/KamAdminReport`;
    const tdrFilterUrl = `${configData.SERVER_URL}/home/KamMedicineTDR`;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const onFilterHandler = async (e) => {
        e.preventDefault();

        const empId = JSON.parse(localStorage.getItem('userData'))?.EmpID;
        const paramObj = {
            empID: empId,
            Startdate: formatDate(startDate),
            Enddate: formatDate(endDate),
        };

        await axios.post(filterUrl, paramObj);
        await axios.post(tdrFilterUrl, paramObj);
    }


    return (
        <>
            <div className='user-dashboard-wrapper'>

                {false && <div className='filter-header'>
                    <div>
                        <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} placeholder='From' />
                        <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} placeholder='To' />
                        <Button label="Filter" onClick={onFilterHandler} />
                    </div>
                    <img src={process.env.PUBLIC_URL + '/img/pdf.png'} alt="React Logo" onClick={exportPDF} />
                </div>}

                <div className="p-3 pb-4">
                    <div className='admin-header-wrapper'>
                        <h1>All India</h1>
                    </div>
                    <PanIndiaDrPatient />
                </div>

                <div className="p-3 pb-4">
                    <div className='admin-header-wrapper'>
                        <h1>Top Performing KAM</h1>
                    </div>
                    <ZonePerformanceByKam />
                </div>

                <div className="p-3 pb-4">
                    <div className='admin-header-wrapper'>
                        <h1>Product Performance Zone Wise</h1>
                    </div>
                    <ZonePerformanceByBrand />
                </div>

                <div className="p-3 pb-4">
                    <div className='admin-header-wrapper'>
                        <h1>Medicine Usage Report</h1>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <ColumnChart />
                        </div>
                    </div>
                </div>

                {
                    false &&
                    <>
                        <div className="p-3 pb-4">
                            <div className="grid">
                                <div className="col-4">
                                    <BrandWisePieChartComparison />
                                </div>

                                <div className="col-4">
                                    <PapZoneWisePieChartComparison />
                                </div>

                                <div className="col-4">
                                    <PatientZoneWisePieChartComparison />
                                </div>

                            </div>
                        </div>

                        <div className="p-3 pb-0">
                            <div className="grid">

                                <div className="col-6">
                                    <LineChart />
                                </div>
                                <div className="col-6">
                                    <DrPatientMonthColumnChart />
                                </div>
                                <div className="col-6">
                                    <PieChart />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
};

export default UserDashboard;