import React from 'react';
import './ZonePerformanceByKam.scss';
import ZonePerformanceByKamItem from './ZonePerformanceByKamItem';
import configData from '../../Config/Config.json';
import { getTopPerformingKamData } from '../../Service/Common';
import { Skeleton } from 'primereact/skeleton';



const ZonePerformanceByKam = (props) => {

    const [topPerformer, setTopPerformer] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);


    React.useEffect(() => {
        const fetchData = async () => {
            await getTopPerformingKamData(`${configData.REPORT_URL}/Reports/ZoneTopKam`, setTopPerformer);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="grid">
                {!isLoading && topPerformer.map((performer, index) => (
                    <React.Fragment key={index}>
                        <ZonePerformanceByKamItem
                            key={index}
                            zoneId={performer.ZoneId}
                            zoneName={performer.ZoneName}                            
                            performer={`${performer.employeename}`}
                            numOfPatients={performer.TotalPatients}
                            vials={performer.TotalVials}
                            pap={performer.TotalPapValue}
                        />
                    </React.Fragment>
                ))}

                {
                    isLoading &&
                    Array.from({ length: 4 }, (_, index) => (
                        <div className="col-3" key={index}><Skeleton height="10rem" animation='wave' shape='rectangle'></Skeleton></div>
                    ))

                }
            </div>
        </>
    );
};

export default React.memo(ZonePerformanceByKam);
