import React from 'react';
import ApexCharts from 'react-apexcharts';
import './ZoneWiseBarChart.css';

const ZoneWiseBarChart = (props) => {

  const [seriesData, setSeriesData] = React.useState([]);
  const [brandName, setBrandName] = React.useState('');
  const [year, setYear] = React.useState('');

  const options = {
    chart: {
      type: 'bar', // Bar chart
      height: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: false, // Vertical bar chart
        columnWidth: '50px',
        dataLabels: {
          position: 'top', // Position of data labels
        },
      },
    },
    xaxis: {
      categories: ['EAST', 'NORTH', 'SOUTH', 'WEST'], // Zones
    },
    legend: {
      position: 'top', // Legend at the top
    },
    title: {
      text: `Zone wise financial year ${brandName} Brand Patients : ${year}`,
      align: 'center',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: ['#7C444F', '#F39E60'], // Custom colors for bars
  };

  React.useEffect(() => {

    const loadData = async () => {
      console.log('latest data', props.data);      
      const seriesDataList = [
        {
          name: 'Patients',
          data: [...props.data.map(item => item.TotalPatients)],
        },
        {
          name: 'Unique Doctors',
          data: [...props.data.map(item => item.UniqueDoctors)],
        }     
      ];
  
      setSeriesData(seriesDataList);
      setBrandName(props.data[0]['MedicineName']);
      setYear(props.year);
    };

    loadData();

    
  }, [props.data]);

  return (
    <div id="ZoneWiseBarChart">
      <ApexCharts options={options} series={seriesData} type="bar" height={600} />
    </div>
  );
};

export default ZoneWiseBarChart;
